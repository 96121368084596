<template>
  <div class="investment">
    <image-box :src="require('../../assets/image/investment/investment_1.jpg')" :height="1399"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_2.jpg')" :height="1354"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_3.jpg')" :height="1662"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_4.jpg')" :height="1251"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_5.jpg')" :height="1070"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_6.jpg')" :height="1263"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_7.jpg')" :height="1076"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_8.jpg')" :height="902"></image-box>
    <image-box :src="require('../../assets/image/investment/investment_9.jpg')" :height="358">
      <a class="investment-btn" href="http://farbeyondrecovery.mikecrm.com/2OBwFwF" target="_blank"></a>
    </image-box>
  </div>
</template>

<script>
import ImageBox from "../../components/ImageBox/ImageBox";

export default {
  name: "investment",
  components: {
    "image-box":ImageBox
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
  @import "investment_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
  @import "investment_mobile";
}
</style>